.cursor-pointer {
    cursor: pointer;
}

.headers {
    background-color: #010101 !important;
}
.form-label {
    color: black;
    font-weight: 650 !important;
}
.form-control {
    color: black !important;
    font-weight: 550 !important;
    padding: 6px !important;
    font-size: 12px;
    background-color: #e8f0fe !important;
    border: 0px !important;
}
.form-select {
    color: #0908084f !important;
    font-weight: 550 !important;
    padding: 6px !important;
    font-size: 12px;
    background-color: #e8f0fe !important;
    border: 0px !important;
}
.form-control:focus,
.form-select:focus {
    color: black !important;
}
.form-control[data-has-value='true'],
.form-select[data-has-value='true'] {
    color: black !important;
}
.custom-form-fields,
.form-control::placeholder {
    color: #0908084f !important;
    font-size: 12px;
}

.plusicon {
    color: #6169d0;
}
.custom-buttons {
    background-color: #6169d0 !important;
    border: none !important;
    color: white;
    border-radius: 10px;
    line-height: 10px;
    font-size: 13px;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-inactive-buttons {
    background-color: white !important;
    border: 1px solid #6169d0 !important;
    border-radius: 10px;
    line-height: 10px;
    font-size: 13px;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-buttons:hover {
    background-color: #525cdf !important;
    /* font-weight: 700; */
    color: white;
    transition-duration: 0.4s;
}

.light-border-bottom {
    border-bottom: 1px solid #313a4615 !important;
}

table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4) !important;
}

.card {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
}

input,
textarea,
select {
    border: 1px solid rgba(15, 13, 13, 0.473) !important;
}

tbody tr:nth-child(even) {
    background-color: #efefef !important;
}

thead {
    background-color: #313a46 !important;
    color: white !important;
}

table {
    width: 100%;
    border-collapse: collapse;
}
.box-shadow-none {
    box-shadow: none !important;
}

.custom-file-upload {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    height: 1.85rem;
    background: #e8f0fe;
}

.file-upload-label {
    display: flex;
    align-items: center;
    width: 100%;
    color: #6c757d;
}

.upload-icon {
    color: #6c757d;
    width: 5vw;
    height: 5vw;
}

.file-input {
    display: none; /* Hide the default file input */
}
.custom-file-upload-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 20px;
    width: 6vw;
    height: 11vh;
    cursor: pointer;
    position: relative;
    border: 1px solid red !important;
}

.scroll-container {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}
body,
body::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

:root {
    overflow: hidden !important;
}
.searchWrapper input {
    border: none !important;
}

.allclinics-info:hover::after {
    content: 'Last Date of Subscription';
    background-color: rgb(44, 42, 42);
    border-radius: 7px;
    padding: 0.5rem 0.8rem;
    margin-top: 1.3rem;
    margin-bottom: 10rem;
    z-index: 111;
    position: absolute;
}
.billing-info:hover::after {
    content: 'The amount displayed is inclusive of all applicable taxes and covers all added clinics.';
    background-color: rgb(44, 42, 42);
    color: white;
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    z-index: 111;
    font-size: 1rem;
    position: absolute;
}

.uil-info-circle {
    font-size: 1rem;
}

.hover-bg-change p:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}
#search_input::placeholder {
    color: #a9a9a982;
}
.form-check-input {
    cursor: pointer !important;
}

#stateSelect {
    color: black;
}

#stateSelect.default-selected {
    color: red;
}
.stateToggle::after {
    display: none !important;
}
table {
    box-shadow: none !important;
}
.checkbox-round {
    width: 0.8em;
    height: 0.8em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: gray;
    border: 1.8px solid black !important;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid gray;
    padding: 8px; /* Adjust padding as needed */
    text-align: left; /* Align text to the left (optional) */
}

/* .form-select {
    color: #a9a9a9 !important;
    font-weight: 550 !important;
} */

.form-select[data-has-value='true'] {
    color: black !important;
    font-weight: 600 !important;
}
.form-select[data-has-value='false'] {
    color: #6c757d !important;
}
.view-docs:hover {
    background-color: #c2bebe75;
    color: black;
    font-weight: 700;
    transition-duration: 0.5s;
}

.emptyField {
    color: #a9a9a9 !important;
}
.selectedField {
    color: black !important;
}
.table > :not(caption) > * > * {
    padding: 0.35rem 0.55rem !important;
    font-size: 14px !important;
}
table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4) !important;
}
